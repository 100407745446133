import { Routes } from '@angular/router';
import { canActivateAlreadyLogin, canActivateAuth, canActivateLogout } from '@app/core/guards/auth.guard';
import { canActivateAddresses } from '@app/core/guards/delivery.guard';
import { canActivateMobile } from '@app/core/guards/is-mobile.guard';
import { PayGuard } from '@app/core/guards/pay.guard';
import { PaymentGuard } from '@app/core/guards/payment.guard';
import { hasUnsavedChanges } from '@app/modals/my-profile/unsaved-changes.guard';
import { dialogableRoute } from '@components/dialog-content/dialogable-route';
import { ProductResolver } from './modals/product-details/product-details.resolver';

const modals = [
  dialogableRoute({
    path: 'login',
    canActivate: [canActivateAlreadyLogin],
    dialog: () => import('@app/modals/auth/login/login.component').then((m) => m.LoginComponent),
    config: { panelClass: 'ryd-dialog', restoreFocus: true },
  }),
  dialogableRoute({
    path: 'registration',
    canActivate: [canActivateAlreadyLogin],
    dialog: () => import('@app/modals/auth/registration/registration.component').then((m) => m.RegistrationComponent),
    config: { panelClass: 'ryd-dialog', restoreFocus: true },
  }),
  dialogableRoute({
    path: 'my-profile',
    canActivate: [canActivateAuth, canActivateMobile(['/personal-details'])],
    dialog: () => import('@app/modals/my-profile/my-profile.component').then((m) => m.MyProfileComponent),
    config: {
      autoFocus: false,
    },
  }),
  dialogableRoute({
    path: 'change-password',
    canActivate: [canActivateAuth],
    dialog: () =>
      import('@app/modals/auth/change-password/change-password-modal.component').then(
        (m) => m.ChangePasswordModalComponent,
      ),
    config: { panelClass: 'login-dialog' },
  }),
  dialogableRoute({
    path: 'order-list',
    canActivate: [canActivateAuth, canActivateMobile(['/personal-details/orders'])],
    dialog: () => import('@app/modals/order-list/order-list.component').then((m) => m.OrderListComponent),
    config: {
      autoFocus: false,
      panelClass: 'ryd-dialog',
    },
  }),
  dialogableRoute({
    path: 'order-list/:orderId',
    canActivate: [canActivateMobile(['/personal-details/orders/:orderId'])],
    data: {
      title: 'personalDetailsOrders',
      label: 'myOrders',
    },
    dialog: () => import('@app/modals/order-history/order-history.component').then((m) => m.OrderHistoryComponent),
  }),
  dialogableRoute({
    path: 'address-list',
    canActivate: [canActivateAuth, canActivateAddresses, canActivateMobile(['/personal-details/addresses'])],
    dialog: () =>
      import('@app/modals/address-list-modal/address-list-modal.component').then((m) => m.AddressListModalComponent),
  }),
  dialogableRoute({
    path: 'shopping-list',
    canActivate: [canActivateAuth, canActivateMobile(['/personal-details/shopping-list'])],
    dialog: () =>
      import('@app/modals/shopping-list-modal/shopping-list-modal.component').then((m) => m.ShoppingListModalComponent),
    config: {
      panelClass: 'ryd-dialog',
    },
  }),
  dialogableRoute({
    path: 'shopping-list/:listId',
    canActivate: [canActivateAuth],
    dialog: () =>
      import('@app/modals/quicklist-detail/quicklist-detail.component').then((m) => m.QuicklistDetailComponent),
    config: {
      panelClass: 'ryd-dialog',
    },
  }),
  dialogableRoute({
    path: 'delivery-time',
    data: {
      title: 'homeDelivery',
    },
    dialog: () =>
      import('@app/modals/update-delivery-time/update-delivery-time.component').then(
        (m) => m.UpdateDeliveryTimeComponent,
      ),
    isBottomSheet: 'mobile',
  }),
];

const productModal = dialogableRoute({
  path: 'product/:productId',
  dialog: () => import('@app/modals/product-details/product-details.component').then((m) => m.ProductDetailsComponent),
  resolve: {
    product: ProductResolver,
  },
  data: {
    title: 'product',
  },
  config: {
    panelClass: 'ryd-product-details-dialog',
    maxHeight: '90vh',
    maxWidth: '90vw',
  },
  isBottomSheet: 'mobile',
});

export const routes: Routes = [
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'en',
    redirectTo: '',
  },
  {
    path: 'he',
    redirectTo: '',
  },
  {
    path: '',
    data: {
      title: 'home',
    },
    loadComponent: () => import('@app/pages/home/home.component').then((m) => m.HomeComponent),
    children: [
      ...modals,
      productModal,
      {
        path: 'search',
        data: {
          title: 'homeSearch',
        },
        loadComponent: () =>
          import('@app/pages/global-search/global-search.component').then((m) => m.GlobalSearchComponent),
      },
      {
        path: 'logout',
        canActivate: [canActivateLogout],
        loadComponent: () => import('@app/pages/empty/empty.component').then((m) => m.EmptyComponent),
      },
    ],
  },
  {
    path: 'privacy-policy',
    data: {
      title: 'privacyPolicy',
    },
    loadComponent: () =>
      import('@app/pages/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
    children: [
      ...modals,
      {
        path: 'search',
        data: {
          title: 'privacyPolicySearch',
        },
        loadComponent: () =>
          import('@app/pages/global-search/global-search.component').then((m) => m.GlobalSearchComponent),
      },
    ],
  },
  {
    path: 'reset',
    data: {
      title: 'reset',
    },
    loadComponent: () => import('@app/pages/recover/recover.component').then((m) => m.RecoverComponent),
  },
  {
    path: 'products',
    data: {
      title: 'products',
    },
    loadComponent: () => import('@app/pages/products/products.component').then((m) => m.ProductsComponent),
    children: [productModal, ...modals],
  },
  {
    path: 'products/:departmentId',
    data: {
      title: 'products',
    },
    loadComponent: () => import('@app/pages/products/products.component').then((m) => m.ProductsComponent),
    children: [
      productModal,
      ...modals,
      {
        path: 'search',
        data: {
          title: 'productsSearch',
        },
        loadComponent: () =>
          import('@app/pages/global-search/global-search.component').then((m) => m.GlobalSearchComponent),
      },
    ],
  },
  {
    path: 'payment',
    data: {
      title: 'payment',
    },
    canActivate: [PaymentGuard],
    loadComponent: () => import('@app/pages/payment/payment.component').then((m) => m.PaymentComponent),
    children: [...modals],
  },
  {
    path: 'pay',
    data: {
      title: 'pay',
    },
    canActivate: [PaymentGuard, PayGuard],
    loadComponent: () =>
      import('@app/pages/payment-iframe/payment-iframe.component').then((m) => m.PaymentIframeComponent),
  },
  {
    path: 'personal-details',
    canActivate: [canActivateAuth],
    data: {
      title: 'personalDetails',
      label: 'myProfile',
    },
    loadComponent: () =>
      import('@app/pages/personal-details/personal-details.component').then((m) => m.PersonalDetailsComponent),
    children: [
      {
        path: '',
        data: {
          title: 'personalDetails',
          label: 'myProfile',
        },
        loadComponent: () => import('@app/modals/my-profile/my-profile.component').then((m) => m.MyProfileComponent),
        canDeactivate: [hasUnsavedChanges],
      },
      {
        path: 'addresses',
        data: {
          mode: 'edit',
          view: 'desktop',
          title: 'personalDetailsAddresses',
          label: 'myAddress',
        },
        loadComponent: () =>
          import('@app/components/address-list/address-list.component').then((m) => m.AddressListComponent),
      },
      {
        path: 'change-password',
        data: {
          title: 'personalDetailsChangePassword',
          label: 'changePassword',
        },
        loadComponent: () =>
          import('@app/components/auth/change-password/change-password.component').then(
            (m) => m.ChangePasswordComponent,
          ),
      },
      {
        path: 'orders',
        data: {
          title: 'personalDetailsOrders',
          label: 'myOrders',
        },
        loadComponent: () => import('@app/components/desktop/orders/orders.component').then((m) => m.OrdersComponent),
      },
      {
        path: 'orders/:orderId',
        data: {
          title: 'personalDetailsOrders',
          label: 'myOrders',
        },
        loadComponent: () =>
          import('@app/modals/order-history/order-history.component').then((m) => m.OrderHistoryComponent),
      },
      {
        path: 'shopping-list',
        data: {
          title: 'personalDetailsShoppingList',
          label: 'myShoppingList',
        },
        loadComponent: () =>
          import('@app/components/shopping-list/shopping-list.component').then((m) => m.ShoppingListComponent),
      },
      {
        path: 'shopping-list/:listId',
        data: {
          title: 'personalDetailsShoppingList',
          label: 'myShoppingList',
        },
        loadComponent: () =>
          import('@app/modals/quicklist-detail/quicklist-detail.component').then((m) => m.QuicklistDetailComponent),
      },
      ...modals,
    ],
  },
  {
    path: 'finish/:orderId',
    data: {
      title: 'finish',
    },
    loadComponent: () => import('@app/pages/finish/finish.component').then((m) => m.FinishComponent),
  },
  {
    path: '**',
    data: {
      title: 'notFound',
    },
    loadComponent: () =>
      import('@app/pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
  },
];
