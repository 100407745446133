import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Product } from '@app/core/entities/product.interface';
import { SiteConfig, SiteConfigSEO } from '@app/core/entities/site-config.interface';
import { environment } from '@env/environment';
import { useSiteConfig } from '@services/site-config.service';
import { TitleService } from './title.service';

const socialPrefixes = {
  Facebook: 'og',
  Twitter: 'twitter',
};

const isTagNotEmpty = (tag: { name: string; content: string | null }): tag is { name: string; content: string } =>
  !!tag.content;

export const getSeoTags = (seo: SiteConfigSEO) =>
  [
    {
      name: 'description',
      content: seo.metaDescription,
    },
    {
      name: 'keywords',
      content: seo.keywords,
    },
  ].filter(isTagNotEmpty);

export const getSocialTags = (seo: SiteConfigSEO) => {
  const socialTags = [];
  for (let item of seo.metaSocials) {
    const prefix = socialPrefixes[item.socialNetwork];
    socialTags.push(
      {
        name: `${prefix}:title`,
        content: item.title,
      },
      {
        name: `${prefix}:description`,
        content: item.description,
      },
      {
        name: `${prefix}:image`,
        content: item.imageUrl ?? environment.storageUrl + '/seo_meta_image_small.jpg',
        itemprop: 'image',
      },
      {
        name: `${prefix}:image:type`,
        content: 'image/jpg',
      },
      {
        name: `${prefix}:image:width`,
        content: '110',
      },
      {
        name: `${prefix}:image:height`,
        content: '110',
      },
    );
  }
  return socialTags.filter(isTagNotEmpty);
};

export const setMetaSEO = () => {
  const meta = inject(Meta);
  const siteConfig = useSiteConfig();

  applyNewSeo(siteConfig(), meta);
};

function applyNewSeo(siteConfig: SiteConfig | undefined, meta: Meta) {
  const seo = siteConfig?.seo;
  if (seo) {
    meta.addTags(getSeoTags(seo));
    meta.addTags(getSocialTags(seo));
  }
}

export function applyProductSeo(product: Product | undefined, isHe: boolean, meta: Meta, title: TitleService) {
  if (product) {
    const pageTitle = isHe ? product.name + ' | מוצר' : 'Products | ' + (product.englishName || product.name);
    title.setTitle(pageTitle);
    const tags = [
      ...getSeoTags({
        metaImageUrl: product!.image as string,
        metaDescription: product!.description,
        metaTitle: product!.name,
        canonicalUrl: null,
        keywords: null,
        metaRobots: null,
        metaSocials: [],
        metaViewport: null,
      }),
      ...getSocialTags({
        metaDescription: null,
        metaImageUrl: null,
        metaTitle: null,
        canonicalUrl: null,
        keywords: null,
        metaRobots: null,
        metaSocials: [
          {
            imageUrl: environment.storageUrl + product!.image + '_small.jpg',
            description: product!.description,
            title: product!.name as string,
            socialNetwork: 'Facebook',
          },
        ],
        metaViewport: null,
      }),
    ];
    for (const tag of tags) {
      const exists = meta.getTag(`name='${tag.name}'`);
      if (exists) {
        meta.updateTag(tag, `name='${tag.name}'`);
      } else {
        meta.addTag(tag, true);
      }
    }
    meta.addTag({ content: 'product.item', name: 'og:type' });
  } else {
    title.setTitle(isHe ? 'מוצר' : 'Products');
  }
}
