import { registerLocaleData } from '@angular/common';
import myLocaleHe from '@angular/common/locales/he';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

registerLocaleData(myLocaleHe);

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://0a8d120bb59a4bc7ba5d93aa87886b71@o4504650647797760.ingest.sentry.io/4504650651598848',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: ['localhost', `https://${location.hostname.split('.')[0]}rydel.io/`],
    tunnel: `${environment.baseUrl}/sentry/tunnel`,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1,
    release: environment.release,
    tracesSampleRate: 1.0,
    denyUrls: ['http://localhost:4201', 'https://rydel-testing.web.app', 'https://rydel-dev.web.app'],
  });
}

bootstrapApplication(AppComponent, appConfig);
