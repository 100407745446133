import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { missingProductImage } from '@app/constants/constants';
import { Product } from '@app/core/entities/product.interface';
import { ProductService } from '@app/services/product.service';
import { applyProductSeo } from '@app/services/setMetaSEO';
import { TitleService } from '@app/services/title.service';
import { useLanguage } from '@app/state/language.repository';
import { getProductsMap } from '@app/state/products-map';
import { createImagePreloader2 } from '@app/utils/image-preloader';
import { first, Observable, of, tap } from 'rxjs';
import { getProductImage } from './firebase-product-image';

@Injectable({ providedIn: 'root' })
export class ProductResolver implements Resolve<Product> {
  private readonly productService = inject(ProductService);
  private readonly language = useLanguage();
  private readonly meta = inject(Meta);
  private readonly title = inject(TitleService);
  private readonly productsMap = getProductsMap();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> {
    const productId = +route.params['productId'];
    if (this.productsMap.has(productId)) {
      return of(this.productsMap.get(productId)!);
    }
    return this.productService.getProduct(productId).pipe(
      tap((product) => this.productsMap.set(productId, product)),
      tap((product) => applyProductSeo(product, this.language.isHe(), this.meta, this.title)),
      tap((product) => this.getHiRezImage(product)),
      first()
    );
  }

  private getHiRezImage = (product: Product) =>
    createImagePreloader2(getProductImage(product, true), product.image || missingProductImage);
}
